import React from 'react';
import { BsExclamationTriangle } from 'react-icons/bs';
import { PophausModal } from '../..';
import { Stack, HStack, Text, Box, Flex, VStack } from '@chakra-ui/react';
import { GetBookingTypesByConfigResult } from 'src/api/interfaces/booking';

interface BookModalData {
  time: string;
  date: string;
  unit: string;
  tickets?: number;
}

interface BookModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick?: () => void;
  handleNextClick?: () => void;
  handleSecondaryClick: () => void;
  type: 'book' | 'view';
  data: BookModalData;
  bookingTypes?: GetBookingTypesByConfigResult[];
}

const BookText = ({ title, value }: { title: string; value: string }) => {
  return (
    <HStack>
      <Text color="white" fontWeight="bold">
        {title}
      </Text>
      <Text color="white">{value}</Text>
    </HStack>
  );
};

const bookProps = {
  mainButtonText: 'reserva de sala',
  nextButtonText: 'ingresso compartilhado',
  secondaryButtonText: 'bloquear horário',
  iconColor: 'green',
  colorScheme: 'popGreen',
};

const viewProps = {
  mainButtonText: 'ver reserva',
  secondaryButtonText: 'desbloquear horário',
  iconColor: 'yellow',
  colorScheme: 'popYellow',
};

export function BookModal({
  isOpen,
  onClose,
  handleMainClick,
  handleNextClick,
  handleSecondaryClick,
  type,
  data,
  bookingTypes,
}: BookModalProps) {
  const typeProps = type === 'book' ? bookProps : viewProps;

  return (
    <PophausModal
      isOpen={isOpen}
      onClose={onClose}
      icon={BsExclamationTriangle}
      overrideContent={
        <Stack direction="column" align="center">
          <BookText title={'HORÁRIO:'} value={data.time} />
          <BookText title={'DIA:'} value={data.date} />
          <BookText title={'UNIDADE:'} value={data.unit} />
          {data.tickets && data.tickets > 0 && (
            <BookText title={'INGRESSOS:'} value={`${data.tickets}`} />
          )}

          <Stack
            direction={'row'}
            alignSelf={'center'}
            color="white"
            mt={2}
            textAlign={'center'}
          >
            {handleMainClick && (
              <VStack
                alignItems={'center'}
                flex={1}
                border={'solid'}
                borderColor={'white'}
                borderWidth={1}
                justifyContent={'center'}
                p={4}
              >
                <Text
                  color="yellow"
                  fontWeight={'bold'}
                >{`Reserva de Sala`}</Text>
                <Text>{`PREÇO POR PESSOA: ${(
                  bookingTypes?.find((bt) => bt.id === 1)?.price || 0
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}</Text>
                <Text>{`SUBTOTAL: ${(
                  (bookingTypes?.find((bt) => bt.id === 1)?.price || 0) *
                  (data.tickets || 0)
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}</Text>
              </VStack>
            )}
            {handleNextClick && (
              <VStack
                alignItems={'center'}
                flex={1}
                border={'solid'}
                borderColor={'white'}
                borderWidth={1}
                justifyContent={'center'}
                p={4}
              >
                <Text
                  color="orange"
                  fontWeight={'bold'}
                >{`Ingresso Compartilhado`}</Text>
                <Text>{`PREÇO POR PESSOA: ${(
                  bookingTypes?.find((bt) => bt.id === 5)?.price || 0
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}</Text>
                <Text>{`SUBTOTAL: ${(
                  (bookingTypes?.find((bt) => bt.id === 5)?.price || 0) *
                  (data.tickets || 0)
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}</Text>
              </VStack>
            )}
          </Stack>
        </Stack>
      }
      handleMainClick={handleMainClick}
      handleNextClick={handleNextClick}
      handleSecondaryClick={handleSecondaryClick}
      {...typeProps}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { Center, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { FormAccordion } from 'src/components/Form/Accordion';
import AdminCalendarFiltersForm, {
  AdminAgendaFilterFormValues,
} from 'src/modules/Forms/Backoffice/AgendaFiltersForm';
import { FormAccordionContent } from 'src/components/Form/Accordion/AccordionContent';
import PageContainer from 'src/components/Container/PageContainer';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BookModal } from 'src/components/Modal/ModalVariants/BookModal';
import { Loading } from 'src/components/Loading';
import { getBusinessUnit } from 'src/api/services/businessUnit';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';
import usePHToast from 'src/hooks/useToast';
import dayjs from 'dayjs';
import { ScheduleButtons } from 'src/components/Form/ScheduleButtons';
import {
  BookingRequest,
  GetBookingTypesByConfigResult,
} from 'src/api/interfaces/booking';
import {
  createBooking,
  getBookingTypesByConfig,
  useGetBookingByID,
} from 'src/api/services/booking';
import {
  createScheduleRule,
  deleteScheduleRule,
  useGetScheduleRules,
} from 'src/api/services/scheduleRule';
import { useDispatch } from 'react-redux';
import { calendarResultActions } from 'src/redux/calendarData';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ScheduleButtonsAdmin } from 'src/components/Form/ScheduleButtonsAdmin';
import { BlockModal } from './CalendarModals';

export default function AdminHome() {
  const toast = usePHToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm<AdminAgendaFilterFormValues>({
    defaultValues: {
      date: dayjs().startOf('day').format('YYYY-MM-DD'),
      businessUnitID: '',
      tickets: 0,
    },
  });
  const { data: scheduleRules, isLoading: isScheduleRulesLoading } =
    useGetScheduleRules();

  const {
    isOpen: isBookingOpen,
    onOpen: onBookingOpen,
    onClose: onBookingClose,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const {
    isOpen: isBlockOpen,
    onOpen: onBlockOpen,
    onClose: onBlockClose,
  } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const { result: calendarResult, search: searchParams } = useSelector(
    (state: RootState) => state.calendarResult
  );

  // Admin Modals
  const [bookingData, setBookingData] = useState<BookingRequest | null>(null);
  const [bookingID, setBookingID] = useState<string | undefined>(undefined);
  const [scheduleRuleID, setScheduleRuleID] = useState<number | undefined>(
    undefined
  );
  const roomData = bookingData?.bookingRooms?.[0];

  const { data: booking } = useGetBookingByID(bookingID);
  const room = booking?.bookingRooms?.[0];

  const [roomAccordionIndex, setRoomAccordionIndex] = useState<number>(0);

  const [selectedPlayerNumber, setSelectedPlayerNumber] = useState(0);

  const [loadingBookingTypes, setLoadingBookingTypes] =
    useState<boolean>(false);
  const [bookingTypes, setBookingTypes] = useState<
    GetBookingTypesByConfigResult[]
  >([]);

  const handleSearch = async (data: AdminAgendaFilterFormValues) => {
    try {
      setLoading(true);
      const result = await getBusinessUnit(+data.businessUnitID);
      const mappedUnit = handleMultipleRooms(result, +data.tickets);
      // dispatch(calendarResultActions.setResult(mappedUnit)) VOLTAR QUANDO HORARIOS COMBINADOS VOLTAREM
      dispatch(calendarResultActions.setResult(result));
      dispatch(calendarResultActions.setSearchParams(data));

      setSelectedPlayerNumber(+data.tickets);

      setLoadingBookingTypes(true);
      const bookingTypes = await getBookingTypesByConfig({
        Tickets: +data.tickets,
        BusinessUnitID: +data.businessUnitID,
        Date: data.date,
      });
      setBookingTypes(bookingTypes);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
      setLoadingBookingTypes(false);
    }
  };

  const handleMultipleRooms = (
    businessUnit: BusinessUnitResult,
    numberOfPlayers: number
  ) => {
    if (numberOfPlayers < 8 || businessUnit.rooms.length < 2)
      return businessUnit;
    const roomIDs = businessUnit.rooms.flatMap((r) => r.roomID);
    const description = `${businessUnit.rooms
      .map((r) => r.description)
      .join(' e ')} (horários combinados)`;
    const combinedRoom = {
      ...businessUnit.rooms[0],
      roomID: roomIDs,
      description,
    };

    const newBusinessUnit = {
      ...businessUnit,
      rooms: [combinedRoom, ...businessUnit.rooms],
    };
    return newBusinessUnit;
  };

  const handleOpenBooking = (data: BookingRequest) => {
    setBookingData(data);
    onBookingOpen();
  };

  const handleBooking = async (bookingTypeID: number) => {
    try {
      if (!bookingData)
        throw new Error('Erro ao fazer a reserva, dados inválidos.');

      setLoading(true);
      const data: BookingRequest = {
        ...bookingData,
        tickets: searchParams.tickets,
        userID: null,
        bookingTypeID,
      };
      const result = await createBooking(data);
      setBookingData(null);

      navigate(`/admin/ingressos/selecionar-usuario/${result.bookingID}`);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  const openBlockModal = () => {
    onBookingClose();
    onBlockOpen();
  };

  const handleLockBooking = async (scheduleRuleId: number) => {
    const roomData = bookingData?.bookingRooms?.[0];
    try {
      setLoading(true);
      if (!roomData?.startTime)
        throw new Error('Horário de início não foi selecionado.');

      const roomIDs =
        bookingData?.bookingRooms.flatMap((br) => br.roomID) ?? [];

      const data = {
        date: searchParams.date,
        startTime: roomData.startTime,
        endTime: roomData?.endTime ?? roomData.startTime,
        open: false,
        ScheduleRuleStatusID: scheduleRuleId,
        roomIDs,
      };

      const _ = await createScheduleRule(data);
      toast({ description: 'Horário bloqueado com sucesso!' });
      onBookingClose();
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setBookingData(null);
      onBlockClose();
      setLoading(false);
    }
  };

  const handleDisabled = async (
    bookingID?: string,
    scheduleRuleID?: number,
    placeInRoomGroup?: boolean
  ) => {
    try {
      if (!bookingID && !scheduleRuleID)
        throw new Error('Reserva inválida. Por favor, recarregue a página.');

      if (placeInRoomGroup === true)
        return navigate(`/admin/ingressos/visualizar/grupo/${bookingID}`);

      setBookingID(bookingID);
      setScheduleRuleID(scheduleRuleID);
      onViewOpen();
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    }
  };

  const handleViewDisabled = () => {
    try {
      if (!bookingID && !scheduleRuleID)
        throw new Error('Ocorreu um erro. Por favor, recarregue a página.');

      if (!bookingID && scheduleRuleID)
        throw new Error('Horário bloqueado, portanto não possui reserva.');

      if (!bookingID)
        throw new Error('Reserva inválida. Por favor, recarregue a página.');

      navigate(`/admin/ingressos/visualizar/${bookingID}`);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    }
  };

  const handleUnlockDisabled = async () => {
    try {
      if (!bookingID && !scheduleRuleID)
        throw new Error('Ocorreu um erro. Por favor, recarregue a página.');

      if (bookingID && !scheduleRuleID)
        throw new Error('Horário reservado, verifique a reserva.');

      if (!scheduleRuleID)
        throw new Error(
          'Horário bloqueado inválido. Por favor, recarregue a página.'
        );

      const _ = await deleteScheduleRule(scheduleRuleID);
      toast({ description: 'Horário desbloqueado com sucesso!' });
      onViewClose();
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={2} align="flex-start" pt={12}>
        <AdminCalendarFiltersForm form={form} overrideSubmit={handleSearch} />
        {loading && (
          <Center w="full">
            <Loading />
          </Center>
        )}
        {!loadingBookingTypes &&
          bookingTypes[0] &&
          !loading &&
          calendarResult?.rooms && (
            <>
              <Text variant="title">SELECIONE O HORÁRIO</Text>
              <FormAccordion
                index={roomAccordionIndex}
                onChange={setRoomAccordionIndex}
              >
                {calendarResult.rooms.map((r, ix) => (
                  <FormAccordionContent
                    key={
                      Array.isArray(r.roomID)
                        ? `combined-${r.roomID[0]}`
                        : r.roomID
                    }
                    title={r.description}
                  >
                    {ix === roomAccordionIndex && (
                      <ScheduleButtonsAdmin
                        businessUnitID={r.businessUnitID}
                        roomIDs={r.roomID}
                        date={searchParams.date}
                        numberOfPlayers={searchParams.tickets}
                        price={r.price}
                        handleContinueClick={handleOpenBooking}
                        handleDisabledClick={handleDisabled}
                      />
                    )}
                  </FormAccordionContent>
                ))}
              </FormAccordion>
            </>
          )}
      </VStack>
      <BookModal
        type="book"
        data={{
          time: `${roomData?.startTime} ÀS ${dayjs(roomData?.endTime, 'HH:mm')
            .add(25, 'minutes')
            .format('HH:mm')}`,
          date: dayjs(form.getValues('date')).format('DD/MM/YYYY'),
          unit: calendarResult?.description ?? '',
          tickets: selectedPlayerNumber,
        }}
        isOpen={isBookingOpen}
        onClose={() => {
          onBookingClose();
          setBookingData(null);
        }}
        handleMainClick={
          bookingTypes.find((bt) => bt.id === 1)
            ? () => handleBooking(1)
            : undefined
        }
        handleNextClick={
          bookingTypes.find((bt) => bt.id === 5) &&
          dayjs(roomData?.endTime, 'HH:mm')
            .add(25, 'minutes')
            .diff(dayjs(roomData?.startTime, 'HH:mm'), 'minutes') == 55
            ? () => handleBooking(5)
            : undefined
        }
        handleSecondaryClick={openBlockModal}
        bookingTypes={bookingTypes}
      />

      <BookModal
        type="view"
        data={{
          time: `${dayjs(room?.startTime).format('HH:mm')} ÀS ${dayjs(
            room?.endTime
          ).format('HH:mm')}`,
          date: dayjs(form.getValues('date')).format('DD/MM/YYYY'),
          unit: calendarResult?.description ?? '',
        }}
        isOpen={isViewOpen}
        onClose={() => {
          onViewClose();
          setBookingID(undefined);
          setScheduleRuleID(undefined);
        }}
        handleMainClick={handleViewDisabled}
        handleSecondaryClick={handleUnlockDisabled}
      />

      <BlockModal
        isOpen={isBlockOpen}
        onClose={onBlockClose}
        scheduleRules={scheduleRules}
        isLoading={isScheduleRulesLoading || loading}
        handleClick={handleLockBooking}
      />
    </PageContainer>
  );
}

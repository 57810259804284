import React, { useCallback, useEffect, useState } from 'react';
import TikTokPixel from 'tiktok-pixel';
import {
  Badge,
  Box,
  Button,
  ButtonProps,
  HStack,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  getSchedulesAvailable,
  getSchedulesAvailableAdmin,
  getSchedulesAvailableForTwoRooms,
} from 'src/api/services/room';
import {
  AvailableHoursResult,
  AvailableHoursResultAdmin,
} from 'src/api/interfaces/availableTime';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { createBooking, postReturnPrices } from 'src/api/services/booking';
import { BookingActions } from 'src/redux/booking';
import usePHToast from 'src/hooks/useToast';
import { formatCurrency } from 'src/utils/format';
import { BookingRequest, PriceRequest } from 'src/api/interfaces/booking';
import { ScheduleButton } from '../ScheduleButton';
import { getDuration, getDurationInFull } from 'src/utils/duration';
import ReactGA from 'react-ga';
import { ScheduleButtonAdmin } from '../ScheduleButtonAdmin';
import dayjs from 'dayjs';
import { Loading } from 'src/components/Loading';

interface ScheduleButtonsAdminProps {
  roomIDs: number | number[];
  date: string;
  numberOfPlayers: number;
  price: number;
  businessUnitID?: number;
  handleContinueClick?: (data: BookingRequest) => void;
  handleDisabledClick?: (bookingID?: string, scheduleRuleID?: number) => void;
}

export const ScheduleButtonsAdmin = ({
  handleContinueClick,
  handleDisabledClick,
  roomIDs,
  date,
  numberOfPlayers,
  price,
  businessUnitID,
  ...props
}: ScheduleButtonsAdminProps & ButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const { user } = useAppSelector((state) => state.user);

  const [schedule, setSchedule] = useState<AvailableHoursResultAdmin[]>([]);
  const [filteredSchedule, setFilteredSchedule] = useState<
    AvailableHoursResultAdmin[]
  >([]);
  const [startTimeIndex, setStartTimeIndex] = useState<number>(-1);
  const [endTimeIndex, setEndTimeIndex] = useState<number>(-1);
  const duration = getDuration(startTimeIndex, endTimeIndex, true);
  const [pricePerPerson, setPricePerPerson] = useState<string>('R$ 0,00');
  const [totalPrice, setTotalPrice] = useState<string>('R$ 0,00');
  const [durationView, setDurationView] = useState<string>('0 minutos');

  const [loading, setLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);

  const [largetThan768] = useMediaQuery('(min-width: 768px)');

  const handleOneRoom = useCallback(async () => {
    try {
      setScheduleLoading(true);
      const result = await getSchedulesAvailableAdmin(roomIDs as number, date);

      setSchedule(result);
      setFilteredSchedule(result);
    } catch {
      return;
    } finally {
      setScheduleLoading(false);
    }
  }, []);

  const handleTwoRooms = useCallback(async () => {
    const result = await getSchedulesAvailableForTwoRooms(
      roomIDs as number[],
      date
    );
    setSchedule(result);
    setFilteredSchedule(result);
  }, []);

  useEffect(() => {
    if (Array.isArray(roomIDs)) {
      handleTwoRooms();
      return;
    }
    handleOneRoom();
  }, []);

  const handleSingleBooking = (
    index: number,
    schedule: AvailableHoursResultAdmin[]
  ) => {
    // caso reserva compartilhada
    const eTIx = index + 1;

    // se o próximo bloco estiver indisponível, não seleciona o bloco clicado
    if (
      schedule.find((s, ix) => !s.available && eTIx == ix) ||
      schedule.length - 1 < eTIx
    )
      return false;

    // seleciona o clicado e também o próximo
    setStartTimeIndex(index);
    setEndTimeIndex(index + 1);

    return true;
  };

  const handleSelect = async (index: number) => {
    if (startTimeIndex === -1) {
      // Primeira seleção
      if (numberOfPlayers < 4) {
        if (!handleSingleBooking(index, schedule)) return;
      } else setStartTimeIndex(index);

      const nextDisabled = schedule.findIndex(
        (s, i) => !s.available && i > index
      );
      const previousDisabled = schedule
        .map((s) => s.available)
        .lastIndexOf(false, index);

      if (nextDisabled === -1 || previousDisabled === -1) return;
      const filtered = schedule.map((s, i) => ({
        ...s,
        available:
          i >= nextDisabled || i <= previousDisabled ? false : s.available,
      }));
      setFilteredSchedule(filtered);
    } else if (index === startTimeIndex) {
      // Deselecionar startTime
      setStartTimeIndex(-1);
      setEndTimeIndex(-1);
      setFilteredSchedule(schedule);
    } else if (index === endTimeIndex && numberOfPlayers >= 4) {
      // Deselecionar endTime
      setEndTimeIndex(-1);
    } else if (startTimeIndex !== -1 && index < startTimeIndex) {
      // Selecionou um startTime mais cedo
      if (numberOfPlayers < 4) {
        if (!handleSingleBooking(index, schedule)) return;
      } else {
        setStartTimeIndex(index);
        setStartTimeIndex(index);
      }
    } else {
      if (numberOfPlayers < 4) {
        if (!handleSingleBooking(index, schedule)) return;
      } else setEndTimeIndex(index);
    }
  };

  // useEffect(() => {
  //   const apiCall = async () => {
  //     setLoading(true);
  //     if (businessUnitID && duration > 0) {
  //       const reqData = {
  //         roomIDs: typeof roomIDs === 'number' ? [roomIDs] : roomIDs,
  //         duration,
  //         tickets: numberOfPlayers,
  //         businessUnitID,
  //         date,
  //       };

  //       const req = await postReturnPrices(reqData);
  //       setDurationView(req.durationFormatted);
  //       setTotalPrice(req.subTotal);
  //       setPricePerPerson(req.pricePerPerson);
  //     }
  //   };

  //   if (duration === 0) {
  //     setDurationView('0 minutos');
  //     setTotalPrice('R$ 0,00');
  //     setPricePerPerson('R$ 0,00');
  //   }

  //   apiCall()
  //     .catch(console.error)
  //     .finally(() => setLoading(false));
  // }, [startTimeIndex, endTimeIndex]);

  const handleContinue = async () => {
    try {
      if (startTimeIndex === -1)
        throw new Error('Por favor, selecione um horário válido.');
      setLoading(true);

      const userID = user?.userID ?? null;
      const startTime = schedule[startTimeIndex].time;
      const endTime =
        endTimeIndex === -1 ? startTime : schedule[endTimeIndex].time;

      let bookingRooms;
      if (Array.isArray(roomIDs)) {
        bookingRooms = roomIDs.map((id) => ({
          roomID: id,
          startTime,
          endTime,
        }));
      } else {
        bookingRooms = [{ roomID: roomIDs, startTime, endTime }];
      }

      if (!businessUnitID) throw new Error('Unidade indefinida!');

      const bookingRequest = {
        date,
        userID,
        tickets: numberOfPlayers,
        bookingRooms,
        bookingTypeID: 1,
        businessUnitID,
      };

      if (handleContinueClick) {
        handleContinueClick(bookingRequest);
        return;
      }

      window.gtag('event', 'add_to_cart');
      TikTokPixel.track('AddToCart', {});

      const result = await createBooking(bookingRequest);

      if (!userID) {
        dispatch(BookingActions.setBookingId(result.bookingID));
        navigate('/login');
        return;
      }

      navigate(`/ingressos/resumo/${result.bookingID}`);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <VStack spacing={12} py={4} px={2}>
      <Stack
        w={'full'}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'space-between'}
      >
        <Stack direction={{ base: 'column', md: 'row' }} alignItems={'center'}>
          <Badge p={1} bg="green">
            disponíveis
          </Badge>
          <Badge p={1} bg="yellow">
            sala inteira
          </Badge>
          <Badge p={1} bg="orange">
            lugar na sala
          </Badge>
          <Badge p={1} bg="blue">
            eventos
          </Badge>
          <Badge p={1} bg="red">
            bloqueados
          </Badge>
        </Stack>
      </Stack>
      {scheduleLoading ? (
        <Loading />
      ) : (
        <Wrap
          spacingX={6}
          spacingY={4}
          align="center"
          justify={{ base: 'center', md: 'unset' }}
        >
          {filteredSchedule.map((s, i) => {
            const isStartOrEnd = i === startTimeIndex || i === endTimeIndex;
            const betweenStartAndEnd = i > startTimeIndex && i < endTimeIndex;
            const isSelected = isStartOrEnd || betweenStartAndEnd;
            const hasPassed = dayjs().isAfter(date, 'day');

            return (
              <WrapItem key={i}>
                <ScheduleButtonAdmin
                  time={s.placeHolder}
                  available={s.available}
                  index={i}
                  isSelected={isSelected}
                  bookingID={s.bookingID || s.placeInRoomGroupID}
                  scheduleRuleID={s.scheduleRuleID}
                  handleSelect={() => handleSelect(i)}
                  handleDisabledClick={handleDisabledClick}
                  owner={s.owner}
                  allPlayersIdentified={s.allPlayersIdentified}
                  label={s.label}
                  numberOfPlayers={s.numberOfPlayers}
                  subLabel={s.subLabel}
                  total={s.total}
                  color={s.color}
                  hasPassed={hasPassed}
                  responsible={s.responsible}
                  placeInRoomGroup={s.placeInRoomGroupID ? true : false}
                />
              </WrapItem>
            );
          })}
        </Wrap>
      )}

      <Stack
        w={'full'}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'space-between'}
      >
        {/* <VStack alignItems={'flex-start'}>
          <Text color="black">{`DURAÇÃO: ${durationView}`}</Text>
          <Text color="black">{`SUBTOTAL: ${totalPrice}`}</Text>
          <Text color="black">{`PREÇO POR PESSOA: ${pricePerPerson}`}</Text>
        </VStack> */}
        <Button
          paddingInline={largetThan768 ? '120px' : 0}
          variant="pophausOutline"
          colorScheme="popGreenAlt"
          isLoading={loading}
          onClick={handleContinue}
          isDisabled={numberOfPlayers >= 9 ? duration < 2 : duration < 1}
          marginStart={'auto'}
        >
          continuar
        </Button>
      </Stack>
    </VStack>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  addBookingPlayers,
  BookingStatusEnum,
  removeBookingPlayers,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import PageContainer from 'src/components/Container/PageContainer';
import usePHToast from 'src/hooks/useToast';
import { BookingActions } from 'src/redux/booking';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { PlayerRequest, PlayerResult } from 'src/api/interfaces/player';
import { useFieldArray, useForm } from 'react-hook-form';
import PlayerDataFieldArrayForm, {
  PlayerDataFieldArrayValues,
} from 'src/modules/Forms/PlayerData/PlayerDataFieldArray';
import { PlayerDataValues } from 'src/modules/Forms/PlayerData';
import dayjs from 'dayjs';
import { AddPlayerModal } from 'src/components/Modal/ModalVariants/AddPlayerModal';
import DashedContainer from 'src/components/Container/DashedContainer';
import { Loading } from 'src/components/Loading';
import { FaRegWindowClose } from 'react-icons/fa';
import { HiOutlinePlusCircle } from 'react-icons/hi2';
import BotsPlaceholder from 'src/components/BotsPlaceholder';

const TicketPlayers = () => {
  const navigate = useNavigate();
  const toast = usePHToast();
  const dispatch = useDispatch();
  const { bookingID } = useParams<{ bookingID: string }>();
  const [loading, setLoading] = useState(false);
  const [bookingPlayers, setBookingPlayers] = useState<PlayerResult[]>([]);
  const { data: booking, isLoading, mutate } = useGetBookingByID(bookingID);
  const [pendingPlayers, setPendingPlayers] = useState<boolean>(false);
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const [searchParams] = useSearchParams();

  const playerForm = useForm<PlayerDataFieldArrayValues>({
    reValidateMode: 'onBlur',
  });

  const addPlayerForm = useForm<PlayerDataValues>({
    reValidateMode: 'onBlur',
    defaultValues: {
      nickName: '',
      name: '',
      lastName: '',
      birthDate: '',
    },
  });

  const [loadingRemovePlayer, setLoadingRemovePlayer] = useState<number>();

  const handleNoBookingID = () => {
    toast({ status: 'error', title: 'ID de reserva não definido!' });
    navigate('/ingressos');
  };

  if (!bookingID) {
    handleNoBookingID();
    return <></>;
  }

  useEffect(() => {
    if (booking?.bookingPlayers) {
      setPendingPlayers(
        booking.bookingPlayers.length >= booking?.tickets ? false : true
      );
      mutate();
    }
  }, [booking]);

  const { control, reset, handleSubmit } = playerForm;

  const { fields, remove: removePlayer } =
    useFieldArray<PlayerDataFieldArrayValues>({
      control: control,
      name: 'playerFieldArray',
    });

  const handleRemovePlayer = (playerIndex: number) => {
    setLoadingRemovePlayer(playerIndex);
    removeBookingPlayers(bookingID, bookingPlayers[playerIndex].playerID)
      .then((res) => {
        setBookingPlayers(bookingPlayers.splice(playerIndex, 1));
        removePlayer(playerIndex);
        mutate();
      })
      .catch((err) => {
        console.log({ err });
        toast({ status: 'error', title: 'Erro ao remover jogador!' });
      })
      .finally(() => setLoadingRemovePlayer(undefined));
  };

  useEffect(() => {
    if (booking && fields) {
      if (fields.length !== booking.tickets) setPendingPlayers(true);
      else setPendingPlayers(false);
    }
  }, [fields, booking]);

  const setPlayerFields = () => {
    const players = booking?.bookingPlayers?.map((p) => ({
      ...p.player,
      birthDate: dayjs(p.player.birthDate).format('DD/MM/YYYY'),
    }));

    reset({ playerFieldArray: players });
  };

  const addPlayer = async (data: PlayerDataValues) => {
    try {
      setLoading(true);
      const bookedPlayers = bookingPlayers?.flatMap((p) => p) ?? [];
      const players = [...bookedPlayers, data];

      const formattedPlayers: PlayerRequest[] = players?.map((p) => {
        const brDate = p.birthDate?.includes('/');
        return {
          ...p,
          birthDate: brDate
            ? dayjs(p.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : p.birthDate,
        };
      });

      const res = await addBookingPlayers(bookingID, formattedPlayers);
      const p = res.bookingPlayers?.flatMap((p) => p.player) ?? bookedPlayers;
      reset({ playerFieldArray: p });
      setBookingPlayers(p);
      mutate();
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      onAddClose();
      addPlayerForm.reset();
      setLoading(false);
    }
  };

  const editPlayers = async (data: PlayerDataFieldArrayValues) => {
    try {
      if (pendingPlayers)
        return toast({
          status: 'warning',
          title: 'Identifique todos os jogadores para continuar!',
        });
      setLoading(true);

      await updateBookingStatus(
        bookingID,
        BookingStatusEnum.AGUARDANDO_PAGAMENTO
      );

      if (searchParams.get('admin') == 'true')
        return navigate(`/admin/ingressos/resumo/${bookingID}`);

      dispatch(
        BookingActions.setBookingStatusID(
          BookingStatusEnum.AGUARDANDO_PAGAMENTO
        )
      );

      toast({
        status: 'success',
        description: 'Jogadores atualizados com sucesso',
      });

      mutate();

      handleContinue();
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const p = booking?.bookingPlayers?.flatMap((p) => p.player);
    setBookingPlayers(p ?? []);
    setPlayerFields();
  }, [booking]);

  const handleContinue = () => {
    dispatch(BookingActions.setBookingId(bookingID));
    navigate(`/ingressos/resumo/${bookingID}`);
  };

  useEffect(() => {
    if (
      booking &&
      ![
        BookingStatusEnum.IDENTIFICANDO_JOGADORES_PRE_PAGAMENTO,
        BookingStatusEnum.AGUARDANDO_USUARIO,
      ].includes(booking.bookingStatusID)
    ) {
      toast({
        status: 'warning',
        title: 'Não é possível identificar jogadores para esta reserva!',
      });
      dispatch(BookingActions.resetBooking());
      navigate('/');
    }
  }, [booking]);

  return (
    <PageContainer containerProps={{ px: 4 }}>
      <Text variant="title" alignSelf={'center'}>
        Identifique os jogadores
      </Text>
      <DashedContainer borderColor={'green'} p={'3rem'} maxW={'1100px'}>
        <VStack spacing={4} w="full" align={'center'}>
          <Text variant="title" alignSelf={'flex-start'}>
            Quem vai jogar?
          </Text>
          <Text variant={'text'} alignSelf={'flex-start'}>
            Preencha os dados de cada jogador para confirmar a reserva. Assim,
            garantimos a melhor experiência para todos.
          </Text>
          {isLoading ? (
            <Center w="full" h="full">
              <Loading />
            </Center>
          ) : (
            <Stack w="full" py={8}>
              {fields?.map((f, i) => (
                <Stack
                  direction={['column', 'row']}
                  key={f.id}
                  align={'flex-end'}
                >
                  <PlayerDataFieldArrayForm
                    playerNum={i + 1}
                    index={i}
                    form={playerForm}
                    colorScheme="yellow"
                  />
                  <Box
                    color="white"
                    as="button"
                    _hover={
                      loadingRemovePlayer === i
                        ? undefined
                        : { color: 'yellow' }
                    }
                    onClick={
                      loadingRemovePlayer === i
                        ? undefined
                        : () => handleRemovePlayer(i)
                    }
                  >
                    <Icon as={FaRegWindowClose} w={5} h={5} />
                    <Text>Excluir</Text>
                  </Box>
                </Stack>
              ))}
              <Box>
                {pendingPlayers ? (
                  <>
                    <Button
                      onClick={onAddOpen}
                      gap={3}
                      p={0}
                      isDisabled={Number(loadingRemovePlayer) >= 0}
                    >
                      <Icon
                        as={HiOutlinePlusCircle}
                        color="green"
                        w={8}
                        h={8}
                      />
                      <Text variant="highlighted" textTransform={'uppercase'}>
                        Adicionar Jogador
                      </Text>
                    </Button>
                  </>
                ) : (
                  <VStack w="100%">
                    {Array.from(
                      {
                        length: booking
                          ? booking.tickets -
                            (booking?.bookingPlayers?.length ?? 0)
                          : 0,
                      },
                      (_, index) => (
                        <BotsPlaceholder key={index} number={index} />
                      )
                    )}
                  </VStack>
                )}
              </Box>
            </Stack>
          )}
        </VStack>
        <Flex
          justifyContent={['center', 'space-between']}
          w={'full'}
          direction={['column', 'row']}
          gap={'1rem'}
        >
          <Text variant={'text'} color={'yellow'}>
            Lembrete: você vai compartilhar a sala e o espaço com jogadores
            desconhecidos.
            <br />
            Prepare-se para fazer novos amigos!
          </Text>
          <Button
            variant="pophausOutline"
            colorScheme={'popGreen'}
            onClick={handleSubmit(editPlayers)}
            isLoading={loading}
            alignSelf={['center', 'flex-end']}
            isDisabled={Number(loadingRemovePlayer) >= 0}
          >
            Continuar
          </Button>
        </Flex>
      </DashedContainer>
      <AddPlayerModal
        key={'addPlayerModal'}
        playerNum={(bookingPlayers?.length ?? 0) + 1}
        form={addPlayerForm}
        isOpen={isAddOpen}
        onClose={() => {
          onAddClose();
          playerForm.reset();
        }}
        handleMainClick={addPlayerForm.handleSubmit(addPlayer)}
        loading={loading}
      />
    </PageContainer>
  );
};

export default TicketPlayers;
